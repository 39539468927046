import consumer from "./consumer"


consumer.subscriptions.create("PingUploadingChannel", {
    connected() {
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        if(window.location.href.indexOf(data['data']['serial_number']) == -1) return; //Message not for this camera
        var message, notice;

        if (window.location.href.indexOf("/es/") > 0) {
            message = data['data']['message_es'];
        }else{ // Fallback in english
            message = data['data']['message_en'];
        }

        notice = '<div class="receiving_picture"><strong>' + message + '</strong></div>';

        $('.receiving_photo_msg').html(notice).fadeIn(300).fadeOut(300).fadeIn(300).fadeOut(300)
                                 .fadeIn(300).fadeOut(300).fadeIn(300).fadeOut(300).fadeIn(300).fadeOut(300);
        // $('.receiving_photo_msg').html(notice).show();
    }
});
