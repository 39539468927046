import consumer from "./consumer"
import $ from 'jquery2';
consumer.subscriptions.create("PingCamChannel", {
    connected() {// Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        if(window.location.href.indexOf(data['data']['serial_number']) == -1) return; //Message not for this camera
        var domain;
        let ping_lag = 3; //seconds
        let ping_delay = (Number($("meta[name='ping_delay']").attr("data-delay")) + ping_lag ) * 1000;

        if ($("meta[name='action']").attr("data-action") === "monorean_cams/show" && $("meta[name='layout']").attr("data-name") === "dark") {
            window.location.replace(data['data']['serial_number'] + '?skip_connecting=true');
        }

        if ($("meta[name='action']").attr("data-action") === "monorean_cams/show" && $("meta[name='layout']").attr("data-name") === "application") {

            if ($('#status-span').hasClass('disconnected')) {
                transit_to_connected_bubble();
            }else{
                clearTimeout(window.time_id_check_connect);
                window.window.time_id_check_connect = setTimeout(transit_to_disconencted_bubble, ping_delay);
                update_symbols();
            }

            function update_symbols(){
                // Remove all posible classes for filling the singal
                $('#signal-levels .signal-bars').removeClass('bars-1 bars-2 bars-3 bars-4 bars-5');
                // Fill batt  symbol
                var signal_bars = (data['data']['signal_level']%20 == 0) ? data['data']['signal_level']/20 : (Math.round(data['data']['signal_level']/20) + 1);
                $('#signal-levels .signal-bars').addClass('bars-' + signal_bars);
                // Replace network type
                $('#network_type').html(data['data']['network_type']);
                // Remove all posible classes filling the battery
                for (var i = 0; i <= 101; i++)
                    $('#batt-levels .gg-battery').removeClass('gg-battery-size-'+i);
                // Fill battery symbol
                $('#batt-levels .gg-battery').addClass('gg-battery-size-' + data['data']['battery_level']);
                $('#battery_percentage').html(data['data']['battery_level']);
                if(data['data']['battery_level'] == "101")
                    $('#batt-levels').addClass('charging');
                else
                    $('#batt-levels').removeClass('charging');

            }

            function transit_to_disconencted_bubble(){
                if ($('#status-span').hasClass('connected')) {
                    $('#status-span').removeClass('connected').addClass('disconnected');
                    $('#last-ping').removeClass('hidden');
                    $('#signal-levels').addClass('hidden');
                    $('#batt-levels').addClass('hidden');
                    $('#status-image').addClass('disconnected');
                    $('#status-image').removeClass('connected');
                    if (window.location.href.indexOf("/es/") != -1)
                        $('#status-span').html(data['data']['disconnected_es']);
                    else
                        $('#status-span').html(data['data']['disconnected_en']);

                }else{
                    return;
                }
            }

            function transit_to_connected_bubble(){
                if (window.location.href.indexOf("/es/") != -1) {
                    $('#last-ping').html(data['data']['ultima_conexion']);
                    $('#status-span').html(data['data']['connected_es']);
                }else{ //Defailt is english
                    $('#last-ping').html(data['data']['last_connection']);
                    $('#status-span').html(data['data']['connected_en']);
                }
                $('#status-span').removeClass('disconnected').addClass('connected');
                $('#last-ping').addClass('hidden');
                $('#signal-levels').removeClass('hidden');
                $('#batt-levels').removeClass('hidden');
                $('#status-image').removeClass('disconnected');
                $('#status-image').addClass('connected');
                update_symbols();
                clearTimeout(window.time_id_check_connect);
                window.time_id_check_connect = setTimeout(transit_to_disconencted_bubble, ping_delay);
            }
        }

        if (document.getElementsByClassName("connecting")[0])
            if (document.getElementsByClassName("connecting")[0].offsetParent != null)
                first_ping_after_sms();
        if (document.getElementsByClassName("connecting")[1])
            if (document.getElementsByClassName("connecting")[1].offsetParent != null)
                first_ping_after_sms();
        function first_ping_after_sms(){
            if ($("meta[name=\'current-url\']").attr("data-url") === 'monorean.cam') {
                domain = 'inbox.monorean.cam';
            } else {
                domain = $("meta[name=\'current-url\']").attr("data-url");
            }

            if (window.location.href.indexOf("/es/") != -1) {
                window.location.replace('http://' + domain + '/es/user_area/cams/' + data['data']['serial_number'] + '?skip_connecting=true');
            }else{ //Default to English
                window.location.replace('http://' + domain + '/user_area/cams/' + data['data']['serial_number'] + '?skip_connecting=true');
            }
        }
    }
});
