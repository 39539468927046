$(document).on("turbolinks:load", function() {
    if (!($('body').data('controller') === 'monorean_cams' && $('body').data('action') === 'show')) {
        return;
    }
    return $('body').on('click', '#load-more-photos', function(event) {
        event.preventDefault();
        $.getScript(this.href);
        return false;
    });
});
