var Page;

Page = class Page {
    constructor() {
        this.controller = this.controller.bind(this);
        // $('body').data('controller')
        this.action = this.action.bind(this);
    }

    controller() {
        return $('meta[name=psj]').attr('controller');
    }

    action() {
        return $('meta[name=psj]').attr('action');
    }

};

// $('body').data('action')
this.page = new Page();