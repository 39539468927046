import consumer from "./consumer"


consumer.subscriptions.create("ReceivedPhotoChannel", {
    connected() {
        // Called when the subscription is ready for use on the server
        console.log("Connected to ReceivedPhotoChannel");
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        console.log(data);
        if(window.location.href.indexOf(data['data']['cam_serial']) == -1) return; //Message not for this camera
        var img, time_ago;

        if (window.location.href.indexOf("/es/") > 0) {
            time_ago = data['data']['time_ago_es'];
            var just_in_class =  "just-in-es";
        }else{ // English as the fallback language
            time_ago = data['data']['time_ago_en'];
            var just_in_class =  "just-in-en";
        }

        if (window.location.href.indexOf(data['cam_serial'])) {
            img = '<div class=\'col-xs-4 cam-photo\' id=\'' + data['data']['photo_id'] + '-img\'> <div class=\'col-xs-12 cam-image\'> <div class=\'text-center '+ just_in_class +'\' id=\'image-div\'> <a href=\'' + data['data']['image_url'] + '\'> <img align=\'middle\' src=\'' + data['data']['image_url'] + '\' width=\'308\' height=\'232\'></a></div><p class=\'col-xs-10 text-13\'>  ' + time_ago + '</p><p id=\'del-image\' class=\'col-xs-2\'><a data-remote=\'true\' rel=\'nofollow\' data-method=\'post\' href=\'/es/user_area/cams/' + data['data']['cam_serial'] + '/remove-photo?photo_id=' + data['data']['photo_id'] + '\'> <img src=\'' + data['data']['icon-delete'] + '\' alt=\'Icon delete\' width=\'24\' height=\'24\'></a></p> </div></div>';
            $('#gallery').prepend(img);
            if ($('.empty-gallery').length > 0) {
                $('.empty-gallery').remove();
            }
            if ($('.no-photos-panel-msg').length > 0) {
                $('.no-photos-panel-msg').remove();
            }

            let audio = new Audio( $('#gallery').attr("data-sound-url") );
            audio.play();

            //Show tip
            // let number_pictures = $('#gallery').children().length
            // if(number_pictures == 3 || number_pictures == 6)
            //     $('#tipMessageModal').modal('show')
            let counter =  $('#gallery').attr('data-msg-contdown')
            if (counter == undefined)
                $('#gallery').attr('data-msg-contdown', 1)
            else
                $('#gallery').attr('data-msg-contdown', (Number($('#gallery').attr('data-msg-contdown'))+ 1))

            counter =  $('#gallery').attr('data-msg-contdown')
            if (counter == "2" || counter == "5")
                $('#tipMessageModal').modal('show');
        }
    }
});
