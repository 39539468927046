$(document).on("turbolinks:load", function() {
    var $close_btn, $gallery_div, $gallery_img_link, $image, $overlay;
    $overlay = $('#overlay');
    $gallery_div = $('#gallery-img-div');
    $image = $('<img>');
    $gallery_div.append($image);
    $close_btn = $('#close-img');
    $gallery_img_link = $('#gallery-img-link');
    $('body').on('click', '#image-div a', function(event) {
        var url;
        event.preventDefault();
        url = $(this).attr('href');
        $image.attr('src', decodeURIComponent(url));
        $gallery_img_link.attr('href', url);
        
        $overlay.show();
        return $('#gallery-img-div img').blowup( {  cursor: false , 
                                                    'background' : '#454545',
                                                    'border': '6px solid #0fb1e7',
                                                    });
    });
    // Manage modal to delet photo
    $('#del-image a').on('click', show_delete_conf);
    function show_delete_conf(event){
        var del_element = this;
        event.preventDefault();
        $('#deletePhotoModal').modal('show');
        $("#del-img-modal-btn").on('click', delete_and_close);
        $('body').on('keypress',function(e){
            if(e.which == 13) {
                delete_and_close(e);
              }
        })
        function delete_and_close(e){
            $('body').off('keypress');
            $("#del-img-modal-btn").off('click');
            $(del_element).off('click',show_delete_conf);
            del_element.click();
            $('#deletePhotoModal').modal('hide');
        };
        return false;
    };

    function transit_to_disconencted_bubble(){
        if ($('#status-span').hasClass('connected')) {
            $('#status-span').removeClass('connected').addClass('disconnected');
            $('#last-ping').removeClass('hidden');
            $('#signal-levels').addClass('hidden');
            $('#batt-levels').addClass('hidden');
            $('#status-image').addClass('disconnected');
            $('#status-image').removeClass('connected');
        }else{
            return;
        }
    }
    let is_demo_area = $("meta[name='is_demo_area']").attr("data-is-demo") == "true"
    if(is_demo_area == false){
        let ping_lag = 3; //seconds
        let ping_delay = (Number($("meta[name='time_to_ping']").attr("data-since-reload")) + ping_lag ) * 1000;
        window.time_id_check_connect = setTimeout(transit_to_disconencted_bubble, ping_delay);
    }else{
        $('#signal-levels').removeClass('hidden');
        $('#batt-levels').removeClass('hidden');
    }


    return $close_btn.click(function() {
        $overlay.hide();
        return $image.attr('src', '');
    });
});
