$(document).on('turbolinks:load', function() {

    $('#copyButton').on('click', function() {
        if (navigator.clipboard && window.isSecureContext)
        navigator.clipboard.writeText($('#clipboardContent').text());
        else
        console.log("Code not copied")
    });

    function restart_videos(){
        $( "video" ).each(function( index ) {
          this.currentTime = 0
          });
    }
    $('li.step.sim').click(function() {
        $('ul.steps').addClass('config_step_1').removeClass('config_step_2').removeClass('config_step_3');
        restart_videos()
    });
    $('div.start-config a.button-config').click(function() {
        restart_videos()
    });
    $('li.step.pin, #go-to-step-2').click(function() {
        $('ul.steps').addClass('config_step_2').removeClass('config_step_1').removeClass('config_step_3');
        restart_videos()
    });
    return $('li.step.config, #go-to-step-3').click(function() {
        $('ul.steps').addClass('config_step_3').removeClass('config_step_1').removeClass('config_step_2');
        restart_videos()
    });
});